import { withOptimizely } from '@optimizely/react-sdk'
import connection from 'modules/sharedb/connection'
import { NextPage } from 'next'
import { ConnectionContext } from '../modules/sharedb/ConnectionContext'

type ClientPageProps = {
  ssr?: JSX.Element | null
}

const ClientPage: React.FC<ClientPageProps> = ({ ssr, children }) => {
  if (!process.browser) {
    return ssr || null
  }

  return (
    <ConnectionContext.Provider value={{ connection }}>
      {children}
    </ConnectionContext.Provider>
  )
}

export default function asClientPage<T>(Component: NextPage<T>) {
  const ComponentWithOptimizely = withOptimizely<any, any>(Component)
  const WithLayoutComponent = (props: T) => (
    <ClientPage>
      <ComponentWithOptimizely {...props} />
    </ClientPage>
  )

  if ('getInitialProps' in Component) {
    WithLayoutComponent.getInitialProps = Component.getInitialProps
  }

  return WithLayoutComponent
}
