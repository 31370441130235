import { DeckOTType } from '@gammatech/ottype-deck'
import ReconnectingWebSocket from 'reconnecting-websocket'
import ShareDB from 'sharedb/lib/client'

import config from 'config'

let connection: ShareDB.Connection
// @ts-ignore
if (process.browser) {
  const socket = new ReconnectingWebSocket(
    `${config('WS_PROTOCOL')}://${config('WS_HOST')}`
  )
  ShareDB.types.register(DeckOTType)
  connection = new ShareDB.Connection(socket as WebSocket)
} else {
  connection = {} as any
}

export default connection
